export default {
  okText: '确定',
  closeText: '关闭',
  cancelText: '取消',
  loadingText: '加载中...',
  saveText: '保存',
  delText: '删除',
  resetText: '重置',
  searchText: '搜索',
  queryText: '立即查询',
  nextText: '下一步',

  inputText: '请输入',
  chooseText: '请选择',

  add: '添加',
  update: '更新',
  refresh: '刷新',
  back: '返回',

  light: '亮色主题',
  dark: '黑暗主题',
  countdown: {
    normalText: '获取验证码',
    sendText: '{0}秒后重新获取',
  },
};
