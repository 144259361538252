export default {
  api: {
    operationSucceed: '操作成功',
    operationFailed: '操作失败',
    errorTip: '错误提示',
    errorMessage: '操作失败，系统异常!',
    timeoutMessage: '登录超时，请重新登录!',
    apiTimeoutMessage: '接口请求超时，请刷新页面重试!',
    apiRequestFailed: '请求出错，请稍候重试',
    networkException: '网络异常',
    networkExceptionMsg: '网络异常，请检查您的网络连接是否正常!',

    errMsg401: '用户没有权限（令牌、用户名、密码错误）!',
    errMsg403: '用户得到授权，但是访问是被禁止的。!',
    errMsg404: '网络请求错误，未找到该资源!',
    errMsg405: '网络请求错误，请求方法未允许!',
    errMsg408: '网络请求超时!',
    errMsg500: '服务器错误，请联系管理员!',
    errMsg501: '网络未实现!',
    errMsg502: '网络错误!',
    errMsg503: '服务不可用，服务器暂时过载或维护!',
    errMsg504: '网络超时!',
    errMsg505: 'http版本不支持该请求!',
  },
  exception: {
    backLogin: '返回登录',
    backHome: '返回首页',
    subTitle403: '抱歉，您无权访问此页面。',
    subTitle404: '抱歉，您访问的页面不存在。',
    subTitle500: '抱歉，服务器报告错误。',
    noDataTitle: '当前页无数据',
    networkErrorTitle: '网络错误',
    networkErrorSubTitle: '抱歉，您的网络连接已断开，请检查您的网络！',
  },
  app: {
    title: '王牌商城',
    description: '王牌商城, 游戏充值, Dmen充值, 代币充值',
    tip: '提示',
    copyright: 'Ace Playing',
    icp: '京ICP备20009004号',
    address: '公司地址: 北京市朝阳区北三环东路28号',
    account: '我的账户',
    changePwd: '修改密码',
    chargeRecord: '充值记录',
    helpCenter: '帮助中心',
    chargeNow: '立即充值',
  },
  login: {
    email: '电子邮箱',
    verifyCode: '验证码',
    password: '密码',
    passwordHolder: '密码（请输入8～30位数字和字母组合）',
    loginButton: '登录',
    freeAccount: '免费注册',
    freeAccountCreate: '免费创建账号',
    forgetPassword: '忘记密码?',
    findPassword: '找回密码',
    findEmailSent: '密码找回邮件已发送至您的邮箱，请点击邮件中的链接重置密码',
    forgetFormTitle: '重置密码',
    oldPassword: '旧密码',
    newPassword: '新密码',
    repeatPassword: '请再次输入密码',
    policyCheck: '请同意',
    policy: '《王牌隐私协议》',
    hadAccount: '已有账号?',
    backSignIn: '返回登录',

    logoutButton: '退出登录',
    registerButton: '注册',

    otherSignIn: '其他登录方式',

    // notify
    loginSucceed: '登录成功',
    logoutSucceed: '您已退出当前账号',
    registerSucceed: '注册成功',
    changePwdSucceed: '密码修改成功',
    resetPwdSucceed: '密码重置成功',

    // ValidateMsg
    emailValidateMsg: '请输入正确的邮箱地址',
    passwordValidateMsg: '请输入8～30位数字和字母组合',
    verifySentMsg: '验证码已发送',
    verifyValidateMsg: '请输入6位数字验证码',
    policyValidateMsg: '同意后才能注册',
    diffPwdValidateMsg: '两次输入密码不一致',
  },
  help: {
    helpModalTitle: '帮助中心',
    helpTipsTitle: '温馨提示',
    helpTips_1: '本平台储值所获得的虚拟道具，仅适用于“D-MEN：The Defenders”。',
    helpTips_2:
      '在您下订单时，请您仔细阅读并确认所购商品的名称、价格、币种、数量、规格、产品详情、下单流程、递送信息、联系方式、支付方式等信息。为避免个人损失，请勿将个人信息透露给他人。',
    serviceTitle: '服务说明',
    service_1:
      '本服务仅面向绑定了“D-MEN：The Defenders”应用的用户。没有进行绑定的用户需要在游戏中完成绑定操作才能使用本服务。',
    service_2:
      '重要：输入账号后，请仔细核对显示的账户信息，确认无误后请再进行下一步操作！（若出现支付错误账户情况，官方将无法为您进行退款操作，敬请谅解！）',
    service_3:
      '付款成功后，系统将发送相应虚拟道具至您的储值账户，请在游戏内查收。若您在储值成功30分钟后未收到购买的虚拟道具，请通过下列方式联系客服，我们将及时为您解决问题。',
    contactTitle: '客服渠道列表',
    contactEmail: '客服邮箱',
    contactFacebook: 'Facebook链接',
    contactDiscord: 'Discord链接',
  },
  charge: {
    title: '商品列表',
    description: '王牌商城, 游戏充值, Dmen充值, 代币充值',
    coinSelect: '币种选择',
    merchandiseList: '游戏商品列表',
    buyNow: '立即抢购',
    quickLimit: '限时秒杀抢购',
    buyLimit: '每个角色限购 {limit} 件',
    chooseRole: '选择角色',
    server: '服务器',
    serverIdHolder: '请输入服务器名称',
    roleID: '角色ID',
    roleIDHolder: '请输入角色ID',
    searchResult: '搜索结果',
    searchAgain: '重新搜索',
    addRole: '添加角色',
    roleInfo: '角色信息',
    roleLevel: '{level} 级',
    gameName: '游戏名称',
    action: '操作',
    confirmDel: '确认删除吗?',
    paymentWay: '支付方式',
    paymentAmount: '支付金额',
    roleLimit: '该角色购买剩余额度: ',
    payNow: '立即付款',
    payJumpTips: '即将跳转到第三方支付平台, 请稍候...',
    paid: '已完成付款',
  },
  orders: {
    title: '订单列表',
    description: '订单列表',
    ordersTitle: '订单列表',
    searchDate: '查询日期',
    selectGame: '选择游戏',
    allGame: '全部游戏',
    orderNo: '订单编号',
    gameName: '游戏名称',
    merchandiseName: '商品名称',
    merchandiseDetail: '商品详情',
    orderAmount: '订单金额',
    createAt: '创建时间',
    orderStatus: '订单状态',
    statusGoing: '支付中',
    statusSucceed: '支付成功',
    statusUnknown: '请前往充值记录查看支付结果',
    statusFailed: '支付失败',
    action: '操作',
    viewDetail: '查看详情',
    detailTitle: '订单详情',
    backToOrders: '返回订单列表',
    orderInfo: '订单信息',
    merchandiseInfo: '商品信息',
    price: '单价',
    quantity: '数量',
    discount: '优惠',
    payResult: '支付结果',
    viewOrder: '查看订单',
  },
  order: {
    title: '订单详情',
    description: '订单详情',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: '支付结果',
    description: '支付结果',
  },
};
